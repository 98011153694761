<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Member Type"
                    label-for="memberType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Member Type"
                      rules=""
                    >
                      <v-select
                        id="memberType"
                        v-model="memberType"
                        taggable
                        push-tags
                        :options="['Individual Investor', 'VC Investor', 'Mentor','Incubator']"
                        label="Member Type"
                        placeholder="Select or Enter Member Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      type="text"
                      placeholder="Name"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Onboarding Date"
                    label-for="onboardedOn"
                  >
                    <b-form-datepicker
                      id="onboardedOn"
                      v-model="onboardedOn"
                      type="text"
                      placeholder="Onboarding Date"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Engagement Details"
                    label-for="engagementDetails"
                  >
                    <b-form-input
                      id="engagementDetails"
                      v-model="engagementDetails"
                      type="text"
                      placeholder="Engagement Details"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Partnership Areas"
                    label-for="partnershipAreas"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Partnership Areas"
                      rules=""
                    >
                      <v-select
                        id="partnershipAreas"
                        v-model="partnershipAreas"
                        taggable
                        push-tags
                        multiple
                        :options="['Programs', 'Events', 'Funding']"
                        label="Partnership Areas"
                        placeholder="Select or Enter Partnership Areas"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Area of Interest tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Area of Interest"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Area of Interest
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Industries/Sectors of Interest"
                    label-for="industries"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Industries/Sectors of Interest"
                      rules=""
                    >
                      <v-select
                        id="industries"
                        v-model="industries"
                        taggable
                        push-tags
                        multiple
                        :options="['Industry 1', 'Industry 2', 'Industry 3']"
                        label="Industries/Sectors of Interest"
                        placeholder="Select or Enter Industries/Sectors of Interest"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Startup stages of Interest"
                    label-for="startupStage"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Startup stages of Interest"
                      rules=""
                    >
                      <v-select
                        id="startupStage"
                        v-model="startupStage"
                        multiple
                        :options="['Ideation', 'Business modelling/prototyping', 'MVP', 'Product Introduction', 'Revenue Generation', 'Scaling/Revenue Growth' ]"
                        label="Startup stages of Interest"
                        placeholder="Select or Enter Startup stages of Interest"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="memberType === 'Individual Investor' || memberType === 'VC Investor'"
                  md="6"
                >
                  <b-form-group
                    label="Investment Ticket Size Lower limit"
                    label-for="ticketSize_l"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Investment Ticket Size Lower limit"
                    >
                      <b-form-input
                        id="ticketSize_l"
                        v-model="ticketSize[0]"
                        placeholder="Lower Limit"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="memberType === 'Individual Investor' || memberType === 'VC Investor'"
                  md="6"
                >
                  <b-form-group
                    label="Investment Ticket Size Upper limit"
                    label-for="ticketSize_u"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Investment Ticket Size Upper limit"
                    >
                      <b-form-input
                        id="ticketSize_u"
                        v-model="ticketSize[1]"
                        placeholder="Upper Limit"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col cols="auto"><h6 class="mt-25">
                      Startup Portfolio
                    </h6></b-col>
                    <b-col>
                      <hr></b-col>
                  </b-row>
                  <b-row
                    v-for="(startup, index) in startupPortfolio"
                    :key="index"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Startup ${index + 1}`"
                        :label-for="`Startup-${index + 1}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`Startup ${index + 1}`"
                        >
                          <b-form-input
                            :id="`Startup-${index + 1}`"
                            v-model="startupPortfolio[index]"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Startup"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="startupPortfolio.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-danger"
                        @click="startupPortfolio.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="index === startupPortfolio.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2 mt-2"
                        variant="outline-primary"
                        @click="startupPortfolio.push('')"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Startup</span>
                      </b-button>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Contact Details tab  -->
          <tab-content
            :before-change="validationFormAddress"
            title="Contact Details"
          >
            <validation-observer
              ref="addressRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Contact Details
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Website"
                    label-for="url"
                  >
                    <b-form-input
                      id="url"
                      v-model="url"
                      type="url"
                      placeholder="Website URL"
                      required
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    label-for="address"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="address"
                      placeholder="Office Address"
                      required
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-row
                    v-for="(item, index) in social"
                    :key="index"
                  >
                    <b-col md="3">
                      <b-form-group
                        label="Social Media"
                        :label-for="`social-${index + 1}`"
                      >
                        <v-select
                          :id="`social-${index + 1}`"
                          v-model="item.type"
                          taggable
                          push-tags
                          :options="['Facebook', 'Twitter', 'LinkedIn']"
                          label="Social Media"
                          placeholder="Select/Enter Social Media"
                          append-to-body
                        />
                      </b-form-group>
                    </b-col>
                    <b-col><b-form-group
                      label="Profile Link"
                      :label-for="`item-${index + 1}`"
                    >
                      <b-form-input
                        :id="`item-${index + 1}`"
                        v-model="item.url"
                        type="url"
                        placeholder="https://"
                      />
                    </b-form-group></b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="social.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-danger"
                        @click="social.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="index === social.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2 mt-2"
                        variant="outline-primary"
                        @click="social.push({
                          type: '',
                          url: '',
                        })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add More</span>
                      </b-button>

                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Primary Contact"
                    label-for="contactName"
                  >
                    <b-form-input
                      id="contactName"
                      v-model="contactName"
                      type="text"
                      placeholder="Primary Contact"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Designation"
                    label-for="contactDesignation"
                  >
                    <b-form-input
                      id="contactDesignation"
                      v-model="contactDesignation"
                      type="text"
                      placeholder="Designation of Contact Person"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      type="tel"
                      placeholder="Phone"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content title="Documentation">
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              >
                <h5 class="mb-0">
                  Documentation
                </h5>
              </b-col>
              <b-col md="12">
                <b-row
                  v-for="(doc, index) in docs"
                  :key="index"
                >
                  <b-col md="3">
                    <b-form-group
                      label="Document Title"
                      :label-for="`Doctype-${index + 1}`"
                    >
                      <b-form-input
                        :id="`Doctype-${index + 1}`"
                        v-model="doc.title"
                        type="text"
                        placeholder="Document Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col><b-form-group
                    label="Upload Document"
                    :label-for="`doc-${index + 1}`"
                  >
                    <b-form-file
                      :id="`doc-${index + 1}`"
                      v-model="doc.file"
                    />
                  </b-form-group></b-col>
                  <b-col sm="auto">
                    <b-button
                      v-if="docs.length > 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      variant="outline-danger"
                      @click="docs.splice(index, 1)"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="XIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="index === docs.length - 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="ml-2 mt-2"
                      variant="outline-primary"
                      @click="docs.push({
                        title: '',
                        file: '',
                      })"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="PlusIcon"
                      />
                      <span>Add Doc</span>
                    </b-button>

                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BButton, BFormFile, BFormGroup, BFormInput, BRow, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      memberType: null,
      name: null,
      onboardedOn: null,
      engagementDetails: null,
      partnershipAreas: null,
      industries: null,
      startupStage: null,
      ticketSize: [null, null],
      startupPortfolio: [null],
      url: null,
      address: null,
      contactName: null,
      contactDesignation: null,
      email: null,
      phone: null,
      social: [
        {
          type: null,
          url: null,
        },
      ],
      docs: [
        {
          title: '',
          file: '',
        },
      ],
      required,
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
